<template>
  <v-container>
    <h2
      class="d-flex justify-center align-center"
      style="text-align: center;"
    >
      {{ $t("message.home_title") }}
    </h2>

    <EstoreCard />

    <!-- 検索欄 -->
    <div class="input-area">
      <v-toolbar
        dark
        color="gray darken-3"
        class="mb-1"
      >
        <v-text-field
          v-model="searchQuery"
          :label="$t('message.search')"
          single-line
          flat
          solo-inverted
          hide-details
          clearable
          prepend-inner-icon="mdi-magnify"
        />
      </v-toolbar>
    </div>

    <div class="d-flex justify-center align-center">
      <div
        v-masonry
        transition-duration="0s"
        item-selector=".item"
        class="masonryWrap"
        :origin-top="true"
        :horizontal-order="false"
      >
        <div
          v-for="(item, index) in events"
          :key="index"
        >
          <div
            v-for="(i, ind) in item.image_urls"
            :key="ind"
            v-masonry-tile
            class="item col-6 col-md-4"
          >
            <img
              :src="i"
              width="100%"
              class="item-image"
              @click="openDialog(i,item)"
            >
          </div>
        </div>
      </div>
    </div>

    <div v-intersect="onIntersect" />

    <v-dialog
      :key="dialogKey"
      v-model="dialog"
      :width="$vuetify.breakpoint.smAndDown ? '95%' : '70%'"
    >
      <v-card
        color="grey-lighten-4"
        flat
      >
        <a
          :href="tweetURL"
          target="_blank"
        >
          <v-img
            :lazy-src="imgSelectedEvent"
            :src="modifiedImageSrc"
            contain
          >
            <template v-slot:placeholder>
              <div class="fill-height ma-0 d-flex align-center justify-center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                />
              </div>
            </template>
          </v-img>
        </a>
        <v-card-title>
          <span class="text-h5" />
        </v-card-title>
        <v-card-text class="flex-center">
          <twitter-card
            :item="dialogItem"
            :user="user"
            :discord-button="discordButton"
            @notShow="notShow"
            @favorite-updated="refreshFavorites"
          />
        </v-card-text>

        <v-card-actions
          class="justify-end pa-3"
          style="position: sticky; bottom: 0; background-color: white;"
        >
          <v-spacer />
          <v-btn
            variant="text"
            color="secondary"
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import TwitterCard from '../components/TwitterCard.vue'

  export default {
    name: 'HomeView',
    components: {
      TwitterCard,
    },
    metaInfo: {
      title: 'Home',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://ff14eden.work' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content: 'FFXIV Housing EdenはFFXIV(ファイナルファンタジー14)でのハウジング情報を配信するサイトです。',
        },
        {
          property: 'og:image',
          content: 'https://ff14eden.work/twitter_card.jpg?3',
        },
      ],
    },
    data: function () {
      return {
        dialog: false,
        dialogKey: 0,
        tweetURL: '',
        imgSelectedEvent: 'https://pbs.twimg.com/media/F1pLwG-aEAIg2vd?format=jpg&name=900x900',
        allEvents: [],
        events: [],
        currentPage: 1,
        perPage: 10,
        isLoading: false,
        dialogItem: '',
        user: true,
        discordButton: false,
        searchQuery: '',
      }
    },
    computed: {
      modifiedImageSrc () {
        let url = ''
        if (this.imgSelectedEvent !== '') {
          url = new URL(this.imgSelectedEvent)
          url.searchParams.delete('name')
        }
        return url.toString()
      },
    },
    watch: {
      // searchQueryが変更されたときに実行される関数
      searchQuery: function (newQuery) {
        this.events = [] // 2. eventのクリア
        this.currentPage = 1 // ページをリセット
        this.loadMoreEvents() // 3. loadMoreEventsの実行
      },
    },
    mounted: async function () {
      await this.fetchEvents()
    },
    methods: {
      // 削除済みツイートの排除
      notShow (item) {
        this.itemsTwitter = this.itemsTwitter.filter(i => i.tweet_id !== item.tweet_id)
      },
      refreshFavorites () {
        this.$emit('favorite-updated') // お気に入りのデータが更新されたことを通知
      },

      openDialog (imageUrl, item) {
        this.imgSelectedEvent = imageUrl
        this.tweetURL = item.tweet_url
        this.dialog = true
        this.dialogItem = item
        this.dialogKey++ // キーの値をインクリメント
        // console.log('ダイアログitem')
        // console.log(item)
      },
      async fetchEvents () {
        if (this.allEvents.length === 0) {
          await axios.get(`../../../artwork/twitter/all_users_twitter.json?timestamp=${new Date().getTime()}`)
            .then(response => {
              this.allEvents = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                .map(event => {
                  event.image_urls = [...new Set(event.image_urls)]
                  return event
                })
            })
        }
        this.loadMoreEvents()
      },
      loadMoreEvents () {
        const existingImageUrlsSet = new Set()
        const baseOfUrl = (url) => new URL(url).origin + new URL(url).pathname
        this.events.forEach(event => {
          event.image_urls.forEach(url => existingImageUrlsSet.add(baseOfUrl(url)))
        })
        const start = (this.currentPage - 1) * this.perPage
        const end = this.currentPage * this.perPage

        let filteredEvents = this.allEvents

        // searchQueryが空でない場合のみフィルタリングを実行
        const searchQuery = this.searchQuery || ''
        if (searchQuery.trim() !== '') {
          filteredEvents = this.allEvents.filter(event => {
            // textまたはuser_nameプロパティが検索クエリを含むかチェック
            return event.text?.toLowerCase().includes(searchQuery.toLowerCase()) ||
             event.user_name?.toLowerCase().includes(searchQuery.toLowerCase())
          })
        }

        if (start < filteredEvents.length) {
          const newEvents = JSON.parse(JSON.stringify(filteredEvents.slice(start, end)))
          newEvents.forEach(event => {
            event.image_urls = event.image_urls.map(url => {
              const newUrl = new URL(url)
              newUrl.searchParams.set('format', 'jpg')
              newUrl.searchParams.set('name', '900x900')
              return newUrl.toString()
            }).filter(url => !existingImageUrlsSet.has(baseOfUrl(url)))
          })
          this.events = this.events.concat(newEvents)
          this.currentPage++
        }
      },

      onIntersect (entries) {
        if (entries[0].isIntersecting && !this.isLoading && this.events.length < this.allEvents.length) {
          this.isLoading = true
          this.loadMoreEvents()
          this.isLoading = false
        }
      },
    },
  }
</script>

<style>
.masonryWrap {
  align: center;
  width: 100%;
}

.item {
  padding: 5px;
}

.item-image {
  width: 100%;
  height: auto;
  margin: 0;
  display: block;
}

.flex-center {
  display: flex;
  justify-content: center; /* 横方向の中央寄せ */
  align-items: center;     /* 縦方向の中央寄せ */
  flex-direction: column;  /* 子要素を縦方向に並べる */
  height: 100%;
}

</style>
